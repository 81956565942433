<template>
    <v-card elevation="0" class="mr-4 mr-md-0 min-width100" height="100%" color="white">
        <router-link :to="{name: 'developer', params: { developer: developer.slug }}" class="text-decoration-none d-flex flex-column justify-space-around bg_white">
            <v-img
                :src="developer.logo_url"
                max-height="263px"
                width="auto"
            ></v-img>
            <v-card-title class="font-weight-medium text-h5 main_color_text text-break ml">
                {{ developer.name }}
            </v-card-title>

            <v-card-title class="font-weight-medium main_color_text qty_buildings mb-3">
                {{ developer.projects_count + ' '
            + declOfNum(developer.projects_count, [$t('headers.project'),$t('headers.project1'),$t('headers.project2')])}}
            </v-card-title>
        </router-link>
    </v-card>
</template>

<script>
export default {
    name: "DeveloperCard",
    props: ['developer'],
    data: function () {
        return {
            loading: false,
        }
    },
    mounted() {
        // console.log(this.developer)
    },
    methods: {
        declOfNum(n, text_forms) {
            n = Math.abs(n) % 100;
            var n1 = n % 10;
            if (n > 10 && n < 20) { return text_forms[2]; }
            if (n1 > 1 && n1 < 5) { return text_forms[1]; }
            if (n1 === 1) { return text_forms[0]; }
            return text_forms[2];
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    }
}
</script>

<style scoped lang="scss">

.v-card__title {
    justify-content: center;
}
.qty_buildings {
    font-size: 18px;
}

.card {
    height: 300px;
    background: white !important;
}

.fz-24 {
    font-size: 24px !important;
    color: #000000;
}


.v-application .mt-auto.price {
    @media all and (max-width: 900px) {
        margin-top: auto;
        color: black;
    }
}

.carousel-circle .v-carousel--hide-delimiter-background .v-carousel__controls {
    @media all and (max-width: 900px) {
        background: whitesmoke !important;
    }
}

.min-width100 {
    min-width: 100%;
}

.v-application .white {
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;

    @media all and (max-width: 900px) {
        background-color: var(--bg) !important;
        border-color: var(--bg) !important;
    }
}

.bg_white {
    background: #ffffff !important;
}
</style>
