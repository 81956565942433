<template>
    <v-card
        v-if="cities.length"
        class="py-2 px-3 mb-9 mb-md-3"
        elevation="0"
        rounded="0"
    >
        <v-list-item class="d-flex flex-column align-baseline pa-lg-3 pa-1">
            <v-list-item-title class="align-self-start text-h6 mb-1 mb-lg-2 ml-0 ml-md-n3 font-weight-medium fz-16 font-set">
                {{ $t('headers.by_city') }}
            </v-list-item-title>
            <v-list-item-subtitle
                v-for="city in cities"
                :key="city.slug"
                class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0"
            >
                <a :href="`/city/${city.slug}`" class="text-decoration-none fz-14 main_color_text">
<!--                <router-link class="text-decoration-none fz-14 main_color_text" :to="{name: 'projects.filtered', params: {filter: 'at-'+city.slug}}">-->
                    {{ $t(city.name) }}
                </a>
            </v-list-item-subtitle>
        </v-list-item>

<!--        <v-list-item class="d-flex flex-column align-baseline pa-lg-3 pa-1">
            <v-list-item-title class="align-self-start text-h6 mb-1 mb-lg-2 ml-0 ml-md-n3 font-weight-medium fz-16 font-set">{{ $t('headers.by_cost') }}</v-list-item-title>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">до 2 500 000 ֏</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">до 3 000 000 ֏</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">до 3 500 000 ֏</a>
            </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="d-flex flex-column align-baseline pa-lg-3 pa-1">
            <v-list-item-title class="align-self-start text-h6 mb-1 mb-lg-2 ml-0 ml-md-n3 font-weight-medium fz-16 font-set">{{ $t('headers.by_class') }}</v-list-item-title>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.economy_class') }}</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.comfort_class') }}</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.business_class') }}</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.elite') }}</a>
            </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="d-flex flex-column align-baseline pa-lg-3 pa-1">
            <v-list-item-title class="align-self-start text-h6 mb-1 mb-lg-2 ml-0 ml-md-n3 font-weight-medium fz-16 font-set">{{ $t('headers.by_year_of_completion') }}</v-list-item-title>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.rented') }}</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.for_rent_in_2021') }}</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.for_rent_in_2022') }}</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.for_rent_in_2023') }}</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="main_color_text text-h6 mb-1 mb-lg-2 font-weight-regular pl-3 pl-md-0">
                <a href="#" class="text-decoration-none fz-14 main_color_text">{{ $t('headers.for_rent_in_2024') }}</a>
            </v-list-item-subtitle>
        </v-list-item>-->
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            cities: 'lists/cities',
        })
    },

    mounted() {
        if (!this.cities.length) {
            this.$store.dispatch('lists/fetchCities')
        }
    },

    beforeDestroy() {
        this.$store.dispatch('lists/resetCities')
    }
}
</script>

<style lang="scss" scoped>
//.v-card {
//    min-height: 784px;
//    @media all and (max-width: 1240px) {
//        min-height: 690px !important;
//    }
//}

.v-list-item::after {
    display: none !important;
}

.font-set {
    font: 400 14px/28px "PT Sans",Arial,"Helvetica Neue",Helvetica,sans-serif !important;
    letter-spacing: 0.32px !important;
}

@media all and (max-width: 1240px) {
    .fz-14 {
        font-size: 14px !important;
    }

    .fz-16 {
        font-size: 16px !important;
    }
}
</style>
