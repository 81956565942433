<template>
    <router-link
        :to="{ name: 'projects.show', params: { project: project.slug } }"
        style="text-decoration: none"
    >
        <v-card class="mx-auto fill-height" elevation="0">
            <v-img :src="project.image_url" height="200px" />

            <v-card-title class="project-card-title">
                {{ project.name }}
            </v-card-title>

            <v-card-subtitle>
                {{ project.completed_at_quarter }} quarter {{ project.completed_at_year }}
            </v-card-subtitle>
        </v-card>
    </router-link>
</template>

<script>
export default {
    props: ['project'],

    data: function () {
        return {
            show: false,
        }
    },
}
</script>

<style scoped>
.project-card-title {
    word-break: break-word;
}
</style>
