<template>
    <v-col v-if="isLoading" xs="12" sm="12" md="9" lg="9" xl="9" cols="12" class="pa-0">
        <v-skeleton-loader class="mt-2 mb-7" type="heading"></v-skeleton-loader>
        <v-row class="white_color_bg ma-0">
            <v-col cols="12">
                <v-skeleton-loader :height="$vuetify.breakpoint.width > 900 ? 400 : 200" type="image@2"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" class="ma-1">
                <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
            <v-col cols="12">
                <v-skeleton-loader :height="$vuetify.breakpoint.width > 900 ? 400 : 200" type="image@2"></v-skeleton-loader>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: "CitiesShowLoadingGrid",
    props: ['isLoading']
}
</script>

<style scoped>

</style>
