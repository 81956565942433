<template>
    <front-layout>
        <v-container fluid style="min-height: calc(100vh - 228px)">
            <v-container>
                <v-row>
                    <v-col v-if="!isLoading" md="9" class="pa-0">
                        <h1 class="mb-4">{{ city.name }}</h1>
                        <v-row class="white_color_bg ma-0">
                            <v-col cols="12">
                                <v-img :src="`${city.image_url}`" :height="$vuetify.breakpoint.width > 900 ? 400 : 200"
                                    alt="City photo"></v-img>
                            </v-col>
                            <v-row class="ma-3 justify-space-between">
                                <span class="city__qty-residential-complexes cursor-pointer"
                                    @click="setHash('#developers')">
                                    {{ $tc('entities.developer', city.developers_count) }}
                                </span>
                                <span class="city__qty-residential-complexes cursor-pointer"
                                    @click="setHash('#projects')">
                                    {{ ($tc('entities.project', city.projects_count)) }}
                                </span>
                            </v-row>
                            <v-col cols="12">
                                <open-street-map-multi-marker v-if="projects[city.id] && projects[city.id].latitude"
                                    :height="$vuetify.breakpoint.width > 900 ? 400 : 200"
                                    :lat="projects[city.id].latitude" :lng="projects[city.id].longitude"
                                    :markers="projects" :zoom="17" :id="'project-map' + city.id" />
                            </v-col>
                        </v-row>
                    </v-col>

                    <cities-show-loading-grid :is-loading="isLoading" />

                    <v-col v-if="$vuetify.breakpoint.width > 900" md="3" class="pt-0 pr-0 pl-0 pl-md-4">
                        <div class="mt-6 mt-md-0">
                            <aside-link-variable-card />
                        </div>

                        <advertising :limit="1" />
                    </v-col>
                </v-row>
            </v-container>
            <v-container v-if="cityProjects.length && cityDevelopers.length">
                <div v-if="cityDevelopers.length" id="developers">
                    <span class="fz-18" v-text="$tc('cities_show.developers')" />
                    <v-row>
                        <v-col cols="4" v-for="developer in slicedCityDevelopers" :key="developer.id">
                            <developer-card :developer="developer" />
                        </v-col>
                    </v-row>
                    <div class="text-center" v-if="canUpArraySlice('cityDevelopers')">
                        <span class="fz-18 cursor-pointer" v-text="$tc('cities_show.more')"
                            @click="upArraySlice('cityDevelopers')" />
                    </div>
                </div>
                <div v-if="cityProjects.length" id="projects">
                    <span class="fz-18" v-text="$tc('cities_show.projects')" />
                    <v-row>
                        <v-col cols="4" v-for="project in slicedCityProjects" :key="project.id">
                            <project-card :project="project" />
                        </v-col>
                    </v-row>
                    <div class="text-center" v-if="canUpArraySlice('cityProjects')">
                        <span class="fz-18 cursor-pointer" v-text="$tc('cities_show.more')"
                            @click="upArraySlice('cityProjects')" />
                    </div>
                </div>
            </v-container>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import AsideLinkVariableCard from "@/components/cards/AsideLinkVariableCard";
import City from "@/models/City";
import Project from "@/models/Project";
import OpenStreetMapMultiMarker from "@/components/OpenStreetMapMultiMarker";
import CitiesShowLoadingGrid from "@/components/CitiesShowLoadingGrid";
import DeveloperCard from "@/components/cards/DeveloperCard";
import ProjectCard from "@/components/cards/ProjectCard";

export default {
    name: "city.show",
    components: {
        ProjectCard,
        DeveloperCard,
        FrontLayout,
        AsideLinkVariableCard,
        OpenStreetMapMultiMarker,
        CitiesShowLoadingGrid,
        Advertising: () => import('@/components/ads/Advertising'),
    },
    computed: {
        cityProjects() {
            return this.city?.projects ?? [];
        },
        cityDevelopers() {
            return Object.values(this.cityProjects?.reduce((accum, { developer }) => {
                if (!developer) {
                    return accum;
                }else if(typeof accum[developer.id] !== "undefined"){
                    accum[developer.id].projects_count++
                }else {
                    accum[developer.id] = developer;
                    accum[developer.id].projects_count = 1;
                }
                return accum;
            }, {}) ?? {});
        },
        slicedCityProjects() {
            return this.cityProjects.slice(0, this.array_slice_props.cityProjects.value)
        },
        slicedCityDevelopers() {
            return this.cityDevelopers.slice(0, this.array_slice_props.cityDevelopers.value)
        }
    },
    data: function () {
        return {
            city: {},
            projects: [],
            isLoading: false,
            first_map_height: 500,
            items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
            slider_term: 0,
            indigo: '#3F44C3',
            green: '#42B261',
            yellow: '#D6A700',
            white: 'white',
            array_slice_props: {
                cityProjects: {
                    default_length: 3,
                    step: 3,
                    value: 3
                },
                cityDevelopers: {
                    default_length: 3,
                    step: 3,
                    value: 3
                }
            },
            goTo: [
                '#developers',
                '#projects',
            ],
            city_name: ''
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.city_title', {city_name: this.city_name}),
            meta: [
                { name: 'description', content: this.$t('meta.city_description', {city_name: this.city_name}), }
            ]
        }
    },
    methods: {
        async init() {
            this.isLoading = true
            this.projects = await Project.get()
            let slug = this.$route.params.city
            if (slug) {
                this.city = await City.custom('/city/slug/' + slug).first()

                this.setCityName()
            }

            this.isLoading = false
        },
        setCityName(){
            if(this.$i18n.locale === 'ru'){
                this.city_name = this.city.name_ru
            }else if(this.$i18n.locale === 'en'){
                this.city_name = this.city.name_en
            }else {
                this.city_name = this.city.name_hy
            }
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        first_map_height_set() {
            this.first_map_height = window.innerWidth > 1240 ? 500 : 200
        },

        upArraySlice(type) {
            const props = this.array_slice_props[type];
            const max = this[type].length;
            let value = props.value + props.step;
            if (value >= max) value = max;
            this.array_slice_props[type].value = value;
        },
        canUpArraySlice(type) {
            const props = this.array_slice_props[type];
            const max = this[type].length;
            let value = props.value;
            return value < max;
        },
        goToElement(target) {
            if (this.goTo.includes(target)) this.$vuetify.goTo(target, { duration: 200, offset: 0, easing: 'linear' });
        },
        setHash(hash) {
            if (this.$route.hash === hash) this.goToElement(hash);
            else this.$router.push({ ...this.$route, hash })
        }
    },
    async mounted() {
        await this.init()
        window.addEventListener("resize", this.first_map_height_set)
        this.first_map_height_set()
        this.goToElement(this.$route.hash)
    },
    watch: {
        '$route.hash'(hash) {
            this.goToElement(hash)
        }
    }
}
</script>

<style lang="scss" scoped>
.city__h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.city__qty-residential-complexes {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--main_color-base);
}

.bank_offer_list__head {
    font-size: 14px;

    @media all and (max-width: 770px) {
        font-size: 12px;
    }
}

.bg_image {
    position: relative;
    z-index: 10;

    &::before {
        content: '';
        display: inline-block;
        background: url('../../../assets/img/project-back.jpg') center center/cover no-repeat no-repeat;
        position: absolute;
        top: -100px;
        right: -12px;
        bottom: -12px;
        width: 2000px;
        height: inherit;
        z-index: -1;
    }
}

.bank-first-row__logo {
    width: 133px;
    height: 133px;
    max-width: 133px;
    max-height: 133px;
    margin-right: 30px;
}

.max-width100 {
    max-width: 100%;
}

.min-width100 {
    min-width: 100%;
}

.max-width300 {
    max-width: 300px;
}

.fz-18 {
    font-size: 18px;
}

.fz-16 {
    font-size: 16px;
}

.fz-24 {
    font-size: 24px;
}

.text--white {
    color: white;
}

.bg_violet {
    background: #8e91d9 !important;
}

.choose-apartment-H2 {
    display: block;
    text-align: left;
    min-width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    padding: 20px 10px;
}

.filter_submit_btn {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.slider_term_text_field {
    position: relative;
    height: 48px;
    color: var(--main_color-base) !important;
}

.slider_term {
    color: var(--main_color-base) !important;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 350px;
}

.wrap_inp {
    position: relative;

    @media all and (max-width: 1240px) {
        max-height: 60px;
    }

    .subtitle_input {
        position: absolute;
        color: white;
        top: -25px;
        left: 0;
        font-size: 16px;
    }
}

.w-310 {
    width: 310px;

    @media all and (max-width: 1240px) {
        width: 100%;
        min-width: 100%;
    }
}

.max-width45 {
    @media all and (max-width: 1240px) {
        max-width: 45%;
    }
}

/* Form violet bg*/
.input_checkbox {
    overflow: hidden;
    background: white;
    padding: 0 12px;
    height: 48px;
    line-height: 20px;
    margin: 0;
    position: relative;

    @media all and (max-width: 770px) {
        margin-bottom: 12px;
    }

    .v-input--selection-controls__ripple,
    .mdi-checkbox-blank-outline,
    .mdi-checkbox-marked {
        width: 20px;
        height: 20px;
        max-width: 20px;
        max-height: 20px;
        font-size: 14px;
        transform: translateX(10px);
    }
}

.input_text_project {
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    font-size: 14px !important;

    label.v-label {
        font-size: 14px !important;
    }
}

.wrap_form_btn {
    display: flex;
    align-items: center;
    justify-content: right;
    min-width: 100%;
    margin-top: 30px;
    max-height: 48px;

    @media all and (max-width: 900px) {
        margin-bottom: 30px;
    }

    @media all and (max-width: 770px) {
        min-width: 100%;
        justify-content: space-between;
    }
}

.wrap_first_line_form {
    max-height: 48px;
    margin-bottom: 15px;

    @media all and (max-width: 1240px) {
        max-height: 100%;
        margin-bottom: 0;
    }
}

.wrap_violet_form {
    margin-top: 30px;
    padding-bottom: 20px;
    position: relative;
    z-index: 9;

    &::before {
        content: '';
        display: inline-block;
        background: #8e91d9 !important;
        position: absolute;
        top: -100px;
        right: -12px;
        bottom: 0;
        width: 3000px;
        height: inherit;
        z-index: -2;
    }
}

.btn__clear {
    min-height: 48px;
}

.bank_offer_list {
    width: 280px;

    @media all and (max-width: 770px) {
        width: 200px;
    }

    .bank_offer_list__text-indigo {
        color: var(--main_color-base);
        text-align: right;

        @media all and (max-width: 770px) {
            font-size: 12px;
        }
    }

    .bank_offer_list__text-green {
        color: var(--green_color-base);
        text-align: right;

        @media all and (max-width: 770px) {
            font-size: 12px;
        }
    }

    .bank_offer_list__text-yellow {
        color: var(--yellow_color-base);
        text-align: right;

        @media all and (max-width: 770px) {
            font-size: 12px;
        }
    }

    .bank_offer_list__item {
        padding: 0;
        font-size: 12px;

        @media all and (max-width: 900px) {
            margin-top: -10px;
            max-height: 25px;
            margin-bottom: -20px !important;
        }
    }
}

.circle_statistic {
    width: 95px;
    height: 95px;
    position: relative;
    margin-right: 10px;

    .progress_circle {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.bg_white {
    background: #fff;
}

.circle-data {
    background: #fff;
    width: 505px;
    height: 171px;
    padding: 30px;

    @media all and (max-width: 1240px) {
        min-width: 100%;
        margin-bottom: 20px;
    }
}

.mortgage-data {
    padding: 0 12px 12px 12px;

    @media all and (max-width: 1240px) {
        min-width: 100%;
    }
}

.mortgage-data__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;

    .mortgage-data__item {
        padding: 0 0 25px 0;
        list-style: none;
        min-width: 45%;

        @media all and (max-width: 1240px) {
            min-width: 0;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        .mortgage-data__head {
            color: #9a9a9a;
            font-size: 12px;

            @media all and (max-width: 770px) {
                font-size: 10px;
            }
        }

        .mortgage-data__value {
            color: var(--main_color-base);
            font-size: 14px;

            @media all and (max-width: 770px) {
                font-size: 12px;
            }
        }
    }
}

.min-beforeLG-width100 {
    @media all and (max-width: 1240px) {
        min-width: 100%;
    }
}

.max-width50-beforeLG {
    @media all and (max-width: 900px) {
        max-width: calc(50% - 12px);
    }
}

.cursor-pointer {
    cursor: pointer;
}
</style>
